<template>
    <el-select :value="value"
               v-loading="loading"
               @change="emit"
               clearable
               @clear="emit(null)"
               filterable
               :placeholder="$t('select.selectValute')">
        <el-option v-for="linija in valute"
                   :key="linija.id"
                   :value="linija.id"
                   :label="linija.naziv">
            <span style="float: left">{{ linija.naziv }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ linija.slovna_sifra }}</span>
        </el-option>
    </el-select>
</template>

<script>
export default {
	name: 'select-valute',
	props: ['value'],
	data() {
		return {
			valute: [],
			loading: true
		};
	},
	mounted() {
		this.$get('valute', { limit: 2000 }).then(data => {
			this.valute = data.content;
			this.$nextTick(() => {
				this.loading = false;
			});
		});
	},
	methods: {
		emit(id) {
			this.$emit('input', id);
		},
		get(id) {
			return this.valute.find(valute => valute.id === id);
		}
	}
};
</script>

