<template>
	<div class="card-box">
		<el-card shadow="hover">
			<el-row>
				<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="!zaDan">
					<el-form-item :label="$t('cene.datumOd')" prop="datum_od" class="width-full">
						<el-date-picker
								:picker-options="pickerOptionsOd"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="dd.MM.yyyy"
								v-model="value.datum_od"
								:clearable="false"
								:editable="false"
								type="date">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="!zaDan">
					<el-form-item :label="$t('cene.datumDo')" prop="datum_do" class="width-full">
						<el-date-picker
								:picker-options="pickerOptionsDo"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="dd.MM.yyyy"
								v-model="value.datum_do"
								:clearable="false"
								:editable="false"
								type="date">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
					<el-form-item :label="$t('cene.vrstaCene')" class="width-full">
						<el-select v-model="value.vrsta_cene">
							<el-option label="Fiksna" value="fiksna"/>
							<el-option label="Parametarska" value="parametarska"/>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover">
			<el-row v-if="!zaDan">
				<el-col :xs="24" :span="8">
					<el-form-item :label="$t('cene.nazivCenovnika')" prop="naziv_cenovnika">
						<el-input v-model="value.naziv_cenovnika"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :span="6">
					<el-form-item :label="$t('cene.linije')" prop="id_linije">
						<select-linije class="full" v-model="value.id_linije" ref="linija"/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-else>
				<el-col :xs="24" :span="6">
					<el-form-item :label="$t('cene.nazivCenovnika')">
						<el-input v-model="value.naziv" :disabled="zaDan"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :span="6">
					<el-form-item :label="$t('cene.linije')">
						<select-linije class="full" :disabled="zaDan" v-model="value.linija" ref="linija"/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover">
			<el-row>
				<el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="6" v-if="!zaDan">
					<el-form-item :label="$t('cene.daniPolazak')">
						<el-checkbox-group v-model="value.dani_polazak" size="mini">
							<el-checkbox-button v-for="dan in daniLista" :label="dan" :key="dan">{{$t('global.daniSkraceno.' + dan)}}</el-checkbox-button>
						</el-checkbox-group>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="6" v-if="!zaDan">
					<el-form-item :label="$t('cene.daniPovratak')">
						<el-checkbox-group v-model="value.dani_povratak" size="mini">
							<el-checkbox-button v-for="dan in daniLista" :label="dan" :key="dan">{{$t('global.daniSkraceno.' + dan)}}</el-checkbox-button>
						</el-checkbox-group>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="6">
					<el-form-item label=" ">
						<el-button @click="dialogVisible = true">{{ $t('cene.starosneGrupe') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover">
			<el-row justify="space-around">
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('cene.valuta')" prop="id_valuta">
						<select-valute class="full" v-model="value.id_valuta" ref="valute"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.vrsta_cene !== 'parametarska'">
					<el-form-item :label="$t('cene.cenaFiksna')" class="width-full">
						<el-input-number v-model="value.cena_fiksna" :min="5" :precision="2" :step="10"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('cene.minCenaPovratne')" class="width-full">
						<el-input-number v-model="value.min_cena_povratne" :min="0" :precision="2" :step="0.1"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('cene.koeficijentPovratne')" class="width-full">
						<el-input-number v-model="value.koeficijent_povratne" :min="1" :precision="2" :step="0.1"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('cene.isplativost')" class="width-full">
						<el-input-number v-model="value.isplativost" :min="5" :precision="2" :step="10"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('cene.brojRaspolozivih')" prop="broj_raspolozivih" class="width-full">
						<el-input-number v-model="value.broj_raspolozivih" @change="getMnozilac()" :min="1" :precision="0" :step="1"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="1" :lg="1" :xl="1">
					<el-form-item label=" ">
						<el-button v-if="!zaDan"
								@click="getOdrediCenuAlert()"
								:disabled="!(value.datum_od > '' &&
											value.datum_do > '' &&
											value.naziv_cenovnika > '' &&
											value.id_linije > 0 &&
											value.id_valuta > 0 &&
											value.broj_raspolozivih > 0 &&
											((value.vrsta_cene === 'parametarska' && value.id_model_obracuna > 0) || value.vrsta_cene === 'fiksna'))">{{$t('cene.isplativost')}}</el-button>
						<el-button v-else
								@click="getOdrediCenuAlert()"
								:disabled="!(value.id_valuta > 0 &&
											value.broj_raspolozivih > 0 &&
											((value.vrsta_cene === 'parametarska' && value.id_model_obracuna > 0) || value.vrsta_cene === 'fiksna'))">{{$t('cene.isplativost')}}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover" v-if="value.vrsta_cene === 'parametarska'">
			<el-row>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('cene.modelObracuna')" prop="id_model_obracuna" class="width-full">
						<select-modeli-obracuna class="full" v-model="value.id_model_obracuna" ref="model_obracuna"/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>


		<el-row v-if="value.id_model_obracuna == 4 && value.vrsta_cene === 'parametarska'">
			<el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="12" class="m--b-20">
				<el-form-item>
					<el-table
							border
							:data="value.cene"
							style="width: 100%">
						<el-table-column
								width="120"
								label="#"
								align="center">
							<template slot-scope="scope">
								<el-button type="danger" size="mini" icon="el-icon-delete" circle @click="deleteRow(scope.$index)"></el-button>
								<el-button v-if="scope.$index + 1 === value.cene.length " type="success" size="mini" icon="el-icon-plus" circle @click="addRow()"></el-button>
							</template>
						</el-table-column>
						<el-table-column
								min-width="200"
								align="center"
								prop="raspolozivo"
								:label="$t('cene.raspolozivo')">
							<template slot-scope="scope">
								<el-input-number v-model="scope.row.raspolozivo" :min="1" :precision="0" :step="1"/>
							</template>
						</el-table-column>
						<el-table-column
								min-width="200"
								align="center"
								prop="cena"
								:label="$t('cene.cena')">
							<template slot-scope="scope">
								<el-input-number v-model="scope.row.cena" :min="5" :precision="2" :step="5"/>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-col>
		</el-row>

		<el-card shadow="hover" v-if="value.vrsta_cene === 'parametarska' && (value.id_model_obracuna == 1 || value.id_model_obracuna == 2 || value.id_model_obracuna == 3)">
			<el-row>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.id_model_obracuna == 1 || value.id_model_obracuna == 2 || value.id_model_obracuna == 3">
					<el-form-item :label="$t('cene.cenaMin')" class="width-full">
						<el-input-number v-model="value.cena_min" @change="getMnozilac()" :min="5" :precision="2" :step="5"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.id_model_obracuna == 1 || value.id_model_obracuna == 2 || value.id_model_obracuna == 3">
					<el-form-item :label="$t('cene.cenaMax')" prop="cena_max" class="width-full">
						<el-input-number v-model="value.cena_max" @change="getMnozilac()" :min="5" :precision="2" :step="5"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.id_model_obracuna == 2">
					<el-form-item :label="$t('cene.stopaRasta')" prop="stopa_rasta" class="width-full">
						<el-input-number v-model="value.stopa_rasta" :min="0.01" :precision="2" :step="0.1"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.id_model_obracuna == 3">
					<el-form-item :label="$t('cene.mnozilac')" class="width-full">
						<el-input-number v-model="value.mnozilac" :min="1.01" :precision="4" :step="0.1"/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover">
			<el-row>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('cene.firstMinute')" class="width-full greey">
						<el-input-number v-model="value.first_minute" :min="0" :precision="2" :step="10"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('cene.lastMinute')" class="width-full">
						<el-select v-model="value.last_minute">
							<el-option label="Da" value="DA"/>
							<el-option label="Ne" value="NE"/>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.last_minute === 'DA'">
					<el-form-item :label="$t('cene.lastMinuteMinIsplativosti')" class="width-full">
						<el-input-number v-model="value.last_minute_min_isplativosti" :min="5" :precision="2" :step="1"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.last_minute === 'DA'">
					<el-form-item :label="$t('cene.lastMinuteCifraZaPrekid')" class="width-full">
						<el-input-number v-model="value.last_minute_max_isplativosti" :min="5" :precision="2" :step="1"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.last_minute === 'DA'">
					<el-form-item :label="$t('cene.lastMinuteFiksnaCena')" class="width-full greey">
						<el-input-number v-model="value.last_minute_fiksna_cena" @change="lastMinitProvera('last_minute_fiksna_cena')" :min="0" :precision="2" :step="10"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.last_minute === 'DA'">
					<el-form-item :label="$t('cene.lastMinuteFiksniPopust')" class="width-full">
						<el-input-number v-model="value.last_minute_fiksni_popust" @change="lastMinitProvera('last_minute_fiksni_popust')" :min="0" :precision="2" :step="0.1"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" v-if="value.last_minute === 'DA'">
					<el-form-item :label="$t('cene.lastMinuteProcenatPopust')" class="width-full">
						<el-input-number v-model="value.last_minute_procenat_popust" @change="lastMinitProvera('last_minute_procenat_popust')" :min="0" :precision="2" :step="0.1"/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover" v-if="value.vrsta_cene === 'parametarska'">
			<el-row>
				<el-col>
					<el-form-item>
						<el-button v-loading="btnLoading" @click="getOdrediGraf()">{{ $t('cene.proracunajMoguceCene') }}</el-button>
						<v-chart v-loading="btnLoading" v-if="vLineSmooth.series.data.length"  style="width: auto" :autoresize="true" :options="vLineSmooth"/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-dialog
				:title="$t('cene.starosneGrupe')"
				:visible.sync="dialogVisible"
				width="80%">
			<div>
				<el-form-item v-if="value.koeficijenti_starosne_grupe.length">
					<el-table
							border
							:data="value.koeficijenti_starosne_grupe"
							style="width: 100%">
						<el-table-column
								width="200"
								prop="naziv"
								:label="$t('cene.starosnaGrupa')">
						</el-table-column>
						<el-table-column
								width="250"
								align="left"
								prop="koeficijent"
								:label="$t('cene.koeficijent')">
							<template slot-scope="scope">
								<el-input-number v-model="scope.row.koeficijent" :disabled="scope.row.naziv.toLowerCase().search('gratis') > -1" :min="0" :precision="2" :step="0.1"/>
							</template>
						</el-table-column>
						<el-table-column
								align="left"
								prop="max_cena_povratne"
								:label="$t('cene.maxCenaPovratne')">
							<template slot-scope="scope">
								<el-input-number v-model="scope.row.max_cena_povratne" :disabled="scope.row.naziv.toLowerCase().search('gratis') > -1" :min="0" :precision="2" :step="0.1"/>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{ $t('global.zatvori') }}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'forma-cene',
	props: {
		value: {
			type: Object
		},
		zaDan: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			btnLoading: false,
			dialogVisible: false,
			pickerOptionsDo: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			pickerOptionsOd: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			vLineSmooth: {
				color: ['#006BB3'],
				xAxis: {
					type: 'category',
					data: []
				},
				tooltip: {
					formatter: (params) => {
						params = params[0];
						return this.$t('cene.raspolozivo') + ': ' + params.name + ' <br/> ' + this.$t('cene.cena') + ': ' + params.value;
					},
					trigger: 'axis',
					axisPointer: {
						label: {
							backgroundColor: '#000000'
						}
					}
				},
				yAxis: {
					type: 'value'
				},
				series: {
					data: [],
					type: 'line',
					smooth: true
				}
			},
			daniLista: [1, 2, 3, 4, 5, 6, 7]
		};
	},
	watch: {
		'value.id_model_obracuna'(id) {
			if (!this.value.id && this.value.id_model_obracuna !== id) {
				this.value.cene = [{
					cena: 0,
					raspolozivo: 1,
					iskorisceno: 0
				}];
			}
		}
	},
	methods: {
		emit() {
			this.$emit('input', this.value);
		},
		getOdrediCenu() {
			return this.$post('odrediCenu', this.value).then(data => {
				return data;
			});
		},
		getOdrediGraf() {
			this.btnLoading = true;
			this.getOdrediCenu().then(data => {
				let podaciCene = [];
				let podaciRaspolozivo = [];
				data.forEach(val => {
					podaciCene.push(val.cena);
					podaciRaspolozivo.push(val.raspolozivo);
				});
				this.vLineSmooth.xAxis.data = podaciRaspolozivo;
				this.vLineSmooth.series.data = podaciCene;
				this.btnLoading = false;
			}).catch(() => {
				this.btnLoading = false;
			});
		},
		getMnozilac() {
			if (this.value.vrsta_cene === 'fiksna' || !this.value.id_model_obracuna || this.value.id_model_obracuna === 2 || this.value.id > 0)
				return;

			let params = {
				cena_min: this.value.cena_min,
				cena_max: this.value.cena_max,
				raspolozivo: this.value.broj_raspolozivih
			};
			return this.$post('cenovnikMnozilac', params).then(data => {
				this.value.mnozilac = data.mnozilac;
			});
		},
		deleteRow(index) {
			this.value.cene.splice(index, 1);
		},
		addRow() {
			this.value.cene.push({ cena: 0, raspolozivo: 1, iskorisceno: 0 });
		},
		lastMinitProvera(polje) {
			switch (polje) {
				case 'last_minute_fiksni_popust':
					this.value.last_minute_procenat_popust = null;
					this.value.last_minute_fiksna_cena = null;
					break;
				case 'last_minute_procenat_popust':
					this.value.last_minute_fiksni_popust = null;
					this.value.last_minute_fiksna_cena = null;
					break;
				case 'last_minute_fiksna_cena':
					this.value.last_minute_fiksni_popust = null;
					this.value.last_minute_procenat_popust = null;
					break;
			}
		},
		getOdrediCenuAlert() {
			if (this.value.vrsta_cene === 'fiksna') {
				this.$alert(this.$t('cene.cenaIsplativostNije') + ': ' + this.value.cena_fiksna * this.value.broj_raspolozivih, this.$t('confirm.upozorenje'), {
					confirmButtonText: this.$t('global.uredu')
				});
				return this.value.cena_fiksna * this.value.broj_raspolozivih;
			}
			else {
				return this.$post('odrediCenu', this.value).then(data => {
					let ukupno = 0;
					data.forEach(val => {
						ukupno = ukupno + val.cena * val.raspolozivo;
					});
					this.$alert(this.$t('cene.cenaIsplativostNije') + ': ' + ukupno, this.$t('confirm.upozorenje'), {
						confirmButtonText: this.$t('global.uredu')
					});
				}).catch(() => {
					return 0;
				});
			}
		}
	},
	components: {
		selectValute: require('../../components/select/valute').default,
		selectLinije: require('../../components/select/linije').default,
		selectModeliObracuna: require('../../components/select/modeliObracuna').default
	}
};
</script>
